<template>
  <div class="data-person-dashboards">
    <div class="dashboards">
      <template v-for="dashboard in dashboards">
        <a
          v-if="dashboard.href"
          :key="dashboard.id"
          :href="dashboard.href"
          :target="dashboard.target"
          @click.prevent="window.open(dashboard.href, dashboard.target)"
        >
          <ui-item
            class="ui-clickable"
            v-bind="dashboard"
          ></ui-item>
        </a>
        <ui-item
          v-else
          :key="dashboard.id"
          class="ui-clickable"
          v-bind="dashboard"
          @click="$emit('click-dashboard', dashboard)"
        ></ui-item>
      </template>
    </div>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi.js';
import apiDataDashboards from '@/modules/data/api/dashboards.js';
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'data-person-dashboards',
  mixins: [useApi],
  $api: apiDataDashboards,

  components: { UiItem },

  props: {
    personId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      window,
      dashboards: [],
    };
  },

  watch: {
    personId: {
      immediate: true,
      handler() {
        this.fetchDashboards();
      },
    },
  },

  methods: {
    async fetchDashboards() {
      try {
        this.dashboards = await this.$api.getPersonDashboards(this.personId);
      } catch (err) {
        this.dashboards = [];
      }
    },
  },
};
</script>