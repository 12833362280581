export default function (httpClient) {

  return {
    // Dashboards globales
    getDashboard(dashboardId, query = undefined) {
      return httpClient.get(`/1/data/dashboards/${dashboardId}`, query);
    },

    // Dashboards de personas
    getPersonDashboards(personId) {
      return httpClient.get(`/1/data/people/${personId}/dashboards`);
    },

    getPersonDashboard(personId, dashboardId, query = undefined) {
      return httpClient.get(`/1/data/people/${personId}/dashboards/${dashboardId}`, query);
    }
  }

}